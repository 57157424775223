import { Heading } from "@chakra-ui/react";
import { NextPage } from "next";

const Page: NextPage = () => {
  return (
    <div>
      <Heading color="core.positive">Menu</Heading>
    </div>
  );
};

export default Page;
